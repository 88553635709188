<template>
 <div id="news" class="news" v-title data-title="新闻列表">
  <!-- 页面导航 -->
  <menu-components type="news"/>

  <!-- 页面主体 -->
  <main class="main">
   <!--banner-->
   <section class="home-banner">
    <el-carousel :autoplay="false" ref="carousel" :interval="5000" arrow="always" height="6.02rem">
     <el-carousel-item v-for="(item, index) in carouselList" :key="index">
      <div class="home-banner-img" :style="{backgroundImage: 'url('+ item.img + ')'}">
       <div class="container">
       <h2>{{item.txt}}</h2>
       <p style="margin-top:.2rem;margin-bottom:.5rem">{{item.txt4}}</p>
       </div>
      </div>
     </el-carousel-item>
    </el-carousel>
   </section>

   <!-- 俱乐部介绍 -->
   <section class="news-1">
    <div class="container">
     <div class="scrollflow -slide-top news-1-t">
      <h2>{{fuse1Details.title1}}</h2>
      <h1>{{fuse1Details.title2}}</h1>
      <p>{{fuse1Details.txt}}</p>
     </div>
     <el-row :gutter="40"  class="home-s-4-l">
      <el-col v-for="(item, index) in newsList" class="home-s-4-l-b" :key="index" :xs="24" :md="8" >
       <div class="home-s-4-i" @click="jumpDetails(item)">
        <img style="margin-top:0;" :src="item.bgUrl" alt="">
        <p class="cph-font">{{item.createTime}}</p>
        <div class="line_tow" style="margin-bottom:0;">{{item.title}}</div>
       </div>
      </el-col>
     </el-row>
     <div class="news-1-b">
      <el-pagination
          style="width: 100%"
          class="cph-font"
          background
          layout="prev, pager, next"
          :page-size="pageParams.limit"
          @current-change="handleCurrentChange"
          :total="total">
      </el-pagination>
     </div>

    </div>
   </section>
  </main>

  <!-- 页面底部 -->
  <footer-components/>
 </div>
</template>

<script>
import MenuComponents from "@/components/Menu/index.vue";
import FooterComponents from "@/components/Footer/index.vue";
import {getNewsData} from '@/api/index.js'
export default {
  name: 'Cooperation',
  components: {
   MenuComponents,
   FooterComponents
  },
  mixins: [],
  data () {
    return {
     // 轮播列表
     carouselList: [
        { img: require('@/assets/images/16.jpg'), txt: '新闻资讯', txt4: 'Media information', jumpUrl: ''},
     ],
     // 当前轮播下标
     initialIndex: 0,
     // 当前方案概述详情
     fuse1Details: {
      title1: '与您分享',
      title2: '公司实时动态',
      txt: 'Company News',
     },
     // 新闻列表
     newsList: [],
     pageParams: {
      page: 1,
      limit: 6,
     },
     total: 0
    };
  },
  watch: {
  },
  mounted () {},
  created () {
   this.getData()
  },
  methods: {
   /* 获取数据 */
   getData () {
    getNewsData(this.pageParams).then(res => {
     if (res.data.code == 0) {
      this.newsList = res.data.data;
      this.total = res.data.count;
     } else {
      this.$message.error(res.msg);
     }
    })
   },
   handleCurrentChange(val) {
    this.pageParams.page = val;
    this.getData();
   },
   /* 跳转详情 */
   jumpDetails (item) {
    this.$router.push({name: 'newsDetails', query: {id: item.id}})
   }
  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
/*  element ui  （分页）*/
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev{
 background: #fff;
}
.el-pagination.is-background .el-pager li{
 background: none;
}
/*  element ui  （分页）*/
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
border-radius: 50%;
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
 background: #4285F4;
}
</style>
